import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBCKGsd2szSw5Dw7pZ4Q9w6ltkkST7AdN8",
  authDomain: "authdomaintest.firebaseapp.com",
  projectId: "authdomaintest",
  storageBucket: "authdomaintest.appspot.com",
  messagingSenderId: "554818551813",
  appId: "1:554818551813:web:dcfa2811c34910014a5c6c"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup, onAuthStateChanged };